import { InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const PasswordAdornment = props => {
    return (
        <InputAdornment position="end" sx={{ "& .MuiSvgIcon-root": { color: "rgba(0, 0, 0, 0.1)" } }}>
            <IconButton onClick={() => props.setShowPassword(showPassword => !showPassword)} tabIndex={-1}>
                {!props.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
        </InputAdornment>
    );
};

export default PasswordAdornment;