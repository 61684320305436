import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";
import { useEffect, useCallback, useRef } from "react";
import { usePullToRefresh } from "../../utils/utils";

const Layout = () => {
    const ref = useRef();

    usePullToRefresh(ref, () => window.location.reload());

    const syncLogout = useCallback(event => {
        if (event.key === "logout") {
            window.location = "/";
        }
    }, []);

    useEffect(() => {
        window.addEventListener("storage", syncLogout);
        return () => { window.removeEventListener("storage", syncLogout) };
    }, [syncLogout]);

    return (
        <Grid container item sx={{ justifyContent: "center", padding: "0px 15px 15px 15px" }} ref={ref}>
            <Outlet />
        </Grid>
    );
};

export default Layout;