import { Grid, Button, Typography, Radio, RadioGroup, FormControlLabel, FormControl, IconButton } from "@mui/material";
import ReactCrop from "react-image-crop";
import { useState, useRef, useEffect } from "react";
import "react-image-crop/dist/ReactCrop.css";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

const CropImage = props => {
    const [crop, setCrop] = useState();
    const imgRef = useRef();
    const [aspect, setAspect] = useState(-1);
    const [imageToCrop, setImageToCrop] = useState(props.image);
    const [imageWidth, setImageWidth] = useState();
    const [imageHeight, setImageHeight] = useState();
    const [scaleFactor, setScaleFactor] = useState();

    // Calculate the scale factor to fit entire image onto screen
    useEffect(() => {
        let image = new Image();

        image.onload = () => {
            setImageHeight(image.height);
            setImageWidth(image.width);

            if (image.width >= image.height) {
                setScaleFactor(Math.min(image.width, props.paperRef.current?.clientWidth * 0.92) / image.width);
            } else {
                setScaleFactor(Math.min(image.height, props.paperRef.current?.clientHeight * 0.45) / image.height);
            }
        };

        image.src = imageToCrop;
    }, [imageToCrop, props.paperRef, imageHeight, imageWidth]);

    const handleNext = () => {
        let image = new Image();
        image.src = imageToCrop;

        image.onload = async () => {
            const canvas = document.createElement("canvas");
            canvas.width = image.width * (crop.width / 100);
            canvas.height = image.height * (crop.height / 100);
            const context = canvas.getContext("2d");
            context.drawImage(image, crop.x, crop.y);
            props.setCroppedImage(canvas.toDataURL("image/png"));
        };
    };

    const onImageLoad = event => {
        setCrop({ unit: "%", x: 0, y: 0, width: 100, height: 100 });
    };

    // Rotates image 90 degrees counterclockwise
    const rotateImage = async () => {
        let image = new Image();
        image.src = imageToCrop;

        image.onload = async () => {
            const canvas = document.createElement("canvas");
            canvas.width = image.height;
            canvas.height = image.width;
            const context = canvas.getContext("2d");
            context.translate(canvas.width / 2, canvas.height / 2);
            context.rotate(-Math.PI / 2);
            context.drawImage(image, -image.width / 2, -image.height / 2);
            const imgUrl = canvas.toDataURL("image/png");
            setImageToCrop(imgUrl);
        };
    };

    return (scaleFactor &&
        <Grid container direction="column">
            <Grid container item justifyContent="center" sx={{ mb: "25px" }}>
                <Typography variant="h5" align="center">Crop / Rotate Image</Typography>
            </Grid>
            <Grid container item justifyContent="center" sx={{ mb: "30px" }}>
                <ReactCrop crop={crop} aspect={aspect < 0 ? undefined : aspect} onChange={(_, percentCrop) => setCrop(percentCrop)}>
                    <img
                        ref={imgRef}
                        alt=""
                        src={imageToCrop}
                        onLoad={onImageLoad}
                        crossOrigin="anonymous"
                        style={{
                            width: imageWidth * scaleFactor,
                            height: imageHeight * scaleFactor
                        }}
                    />
                </ReactCrop>
            </Grid>
            <Grid container item spacing={2.5} justifyContent="center">
                <Grid item>
                    <FormControl sx={{ textAlign: "center" }}>
                        <RadioGroup
                            row
                            defaultValue={1}
                            value={aspect}
                            onChange={event => setAspect(event.target.value === "1" ? 1 : -1)}
                        >
                            <FormControlLabel value={1} control={<Radio />} label="Square" sx={{ marginRight: "0px", justifyContent: "center" }} />
                            <FormControlLabel value={-1} control={<Radio />} label="Free-form" sx={{ marginLeft: "16px", marginRight: "0px", justifyContent: "center" }} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item sx={{ ml: 4 }}>
                    <IconButton onClick={() => rotateImage()} sx={{ color: "#274c77" }}>
                        <RotateLeftIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container item justifyContent="center">
                <Button
                    onClick={handleNext}
                    sx={{
                        width: "100%",
                        marginTop: "35px",
                        height: "45px",
                        marginBottom: "35px",
                        backgroundColor: "rgba(42, 157, 143, 0.6)",
                        color: "#495464",
                        "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.6)" }
                    }}
                >
                    Next
                </Button>
            </Grid>
        </Grid >
    );
};

export default CropImage;