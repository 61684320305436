import { Typography } from "@mui/material";

const DotMarkerWithNumber = props => {
    const diameter = props.imageWidth ? parseInt(props.imageWidth) * 0.04 : 0;
    const fontSize = props.imageWidth ? parseInt(props.imageWidth) * 0.02 : 0;

    return (
        <div style={{ borderRadius: "50%", width: diameter, height: diameter, background: "#e76161", alignContent: "center" }}>
            <Typography align="center" sx={{ color: "#ffffff", fontSize: fontSize }}>{props.markerComponentProps.index + 1}</Typography>
        </div>
    );
};

export default DotMarkerWithNumber;