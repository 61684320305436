import { Grid, Paper, Button, TextField, Typography, Modal, IconButton, Autocomplete } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import ErrorBox from "../Common/ErrorBox";
import { UserContext } from "../../context/UserContext";
import { formatCurrency } from "../../utils/utils";
import CloseIcon from "@mui/icons-material/Close";
import { axiosInstance } from "../../utils/utils";
import dayjs from "dayjs";

const SendModal = props => {
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const detailsTextFieldParams = { type: "text", fullWidth: true, variant: "outlined", onChange: event => handleChange(event.target.name, event.target.value) };
    const [userContext, setUserContext] = useContext(UserContext);
    const [, setModified] = useState(false);
    const [message, setMessage] = useState({ to: userContext.defaultSendEmail, cc: userContext.defaultCcEmail, text: "", subject: "" });
    const autocompleteParams = { fullWidth: true, freeSolo: true, autoSelect: false, autoComplete: true, autoHighlight: false, blurOnSelect: true };

    const handleChange = (name, value) => {
        setMessage(pairs => ({ ...pairs, [name]: value }));
        setModified(true);
    };

    const handleClose = () => props.setOpenModal(false);

    useEffect(() => {
        const fieldsNotEmpty = () => {
            return message.to && message.text && message.subject;
        };

        setDisableSubmitButton(!fieldsNotEmpty());
    }, [message]);

    useEffect(() => {
        const fillSubject = () => {
            let name = "";

            if (userContext.name) {
                name = userContext.name;
            } else {
                name = userContext.username.charAt(0).toUpperCase() + userContext.username.slice(1).toLowerCase();
            }

            setMessage(message => ({ ...message, subject: "Invoice for " + props.project.name + " [from " + name + "]" }));
        };

        const fillText = () => {
            let url = process.env.REACT_APP_API_URL + "/workareas?project_id=" + props.project._id + "&billable=true&sort=index:asc";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    let msg = "Project Information:\n\n";

                    msg += "Vessel Name: " + props.project.name;

                    if (props.project.description) {
                        msg += "\nVessel Length and Type: " + props.project.description;
                    }

                    if (props.project.person) {
                        msg += "\n\nContact Name: " + props.project.person;
                    }

                    if (props.project.phone) {
                        msg += "\nPhone: " + props.project.phone;
                    }

                    if (props.project.email) {
                        msg += "\nEmail: " + props.project.email;
                    }

                    if (res.data.workAreas.length > 0) {
                        msg += "\n\n---------------------------------------------";
                        msg += "\n\nTime & Materials:\n";
                    }

                    res.data.workAreas.forEach((workArea, index) => {
                        msg += "\n#" + (workArea.index + 1) + " - " + workArea.name;

                        if (workArea.startDate) {
                            msg += "\nStart: " + dayjs(workArea.startDate).format("ddd, MM/DD/YYYY");
                        }

                        if (workArea.startDate && workArea.endDate) {
                            msg += " - ";
                        }

                        if (workArea.endDate) {
                            if (!workArea.startDate) {
                                msg += "\n";
                            }

                            msg += "End: " + dayjs(workArea.endDate).format("ddd, MM/DD/YYYY");
                        }

                        if (workArea.description) {
                            msg += "\n" + workArea.description;
                        }

                        if (workArea.time) {
                            msg += "\nTime: " + workArea.time + " hours";
                        }

                        if (workArea.expense) {
                            msg += "\nMaterials: " + formatCurrency(workArea.expense);
                        }

                        if (index < res.data.workAreas.length - 1) {
                            msg += "\n";
                        }
                    });

                    setMessage(message => ({ ...message, text: msg }));
                })
                .catch(err => {
                    // Do nothing
                });
        }

        fillSubject();
        fillText();
    }, [props.project, userContext, setUserContext]);

    const handleSubmit = event => {
        event.preventDefault();
        setDisableSubmitButton(true);

        const url = process.env.REACT_APP_API_URL + "/projects/send";
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        const payload = message;

        axiosInstance.post(url, payload, config)
            .then(res => {
                props.setOpenModal(false);
                props.setSnackbarMessage("Request sent");
                props.setOpenSnackbar(true);
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableSubmitButton(false);
            });
    };

    return (
        <Modal open={props.openModal} onClose={handleClose}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: 900,
                    width: "96%",
                    height: "96%",
                    bgcolor: "#ffffff",
                    overflow: "auto"
                }}
            >
                <Grid container item justifyContent="flex-end" sx={{ padding: "2.5% 2.5% 2.5% 2.5%" }}>
                    <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                </Grid>
                <div style={{ padding: "0% 4% 4% 4%" }}>
                    <Grid container item justifyContent="center" sx={{ mb: "25px" }}>
                        <Typography variant="h5" align="center">Request Billing</Typography>
                    </Grid>
                    <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px" }}>
                        <Typography color="black">Send a request to invoice this project</Typography>
                    </Grid>
                    <form onSubmit={handleSubmit}>
                        <Grid container direction="column" spacing={4}>
                            <Grid container item>
                                <Autocomplete
                                    {...autocompleteParams}
                                    options={[]}
                                    renderInput={params => (<TextField {...params} type="email" label="To" value={message.to} inputProps={{ ...params.inputProps, autoCapitalize: "none" }} InputLabelProps={{ shrink: true }} />)}
                                    onInputChange={(event, value) => handleChange("to", value)}
                                    inputValue={message.to}
                                />
                            </Grid>
                            <Grid container item>
                                <Autocomplete
                                    {...autocompleteParams}
                                    options={[]}
                                    renderInput={params => (<TextField {...params} type="cc" label="Cc" value={message.cc} inputProps={{ ...params.inputProps, autoCapitalize: "none" }} InputLabelProps={{ shrink: true }} />)}
                                    onInputChange={(event, value) => handleChange("cc", value)}
                                    inputValue={message.cc}
                                />
                            </Grid>
                            <Grid container item>
                                <Autocomplete
                                    {...autocompleteParams}
                                    options={[]}
                                    renderInput={params => (<TextField {...params} label="Subject" value={message.subject} inputProps={{ ...params.inputProps, autoCapitalize: "words" }} InputLabelProps={{ shrink: true }} />)}
                                    onInputChange={(event, value) => handleChange("subject", value)}
                                    inputValue={message.subject}
                                />
                            </Grid>
                            <Grid container item>
                                <TextField {...detailsTextFieldParams} name="text" label="Message" multiline rows={20} value={message.text} InputLabelProps={{ shrink: true }} inputProps={{ autoCapitalize: "sentences" }} />
                            </Grid>
                            <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                            <Grid container item justifyContent="flex-end">
                                <Grid item sx={{ mr: 2.5 }}>
                                    <Button
                                        variant="outlined"
                                        sx={{ height: "45px", width: "120px" }}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        sx={{ height: "45px", width: "120px" }}
                                        type="submit"
                                        disabled={disableSubmitButton}
                                    >
                                        Send
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Paper>
        </Modal>
    );
};

export default SendModal;