import { Modal, Paper, Grid, IconButton } from "@mui/material";
import ImageMarker from "react-image-marker";
import DotMarker from "./DotMarker";
import CloseIcon from "@mui/icons-material/Close";
import { useRef, useState } from "react";

const MarkedImageModal = props => {
    const blueprintRef = useRef(null);
    const [blueprintWidth, setBlueprintWidth] = useState();

    const handleClose = () => {
        props.setImage();
        props.setOpenModal(false);
    };

    return (
        <Modal open={props.openModal} onClose={handleClose}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxHeight: "96%",
                    width: "96%",
                    maxWidth: 900,
                    overflow: "auto"
                }}
            >
                <Grid container item justifyContent="flex-end" sx={{ padding: "2.5% 2.5% 2.5% 2.5%" }}>
                    <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                </Grid>
                <Grid container item alignItems="center" sx={{ padding: "0% 4% 4% 4%", minHeight: "87%" }}>
                    <div onLoad={() => setBlueprintWidth(blueprintRef.current.offsetWidth)} ref={blueprintRef}>
                        <ImageMarker
                            src={props.image}
                            markers={props.markers}
                            markerComponent={() => <DotMarker imageWidth={blueprintWidth} />}
                        />
                    </div>
                </Grid>
            </Paper>
        </Modal >
    );
};

export default MarkedImageModal;