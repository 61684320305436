import { Grid, Paper, Button, TextField, Typography, Modal, FormControlLabel, Checkbox, IconButton, Autocomplete } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import ErrorBox from "../Common/ErrorBox";
import CloseIcon from "@mui/icons-material/Close";
import CopyAdornment from "../Common/CopyAdornment";
import { UserContext } from "../../context/UserContext";
import { axiosInstance } from "../../utils/utils";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const LinksModal = props => {
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const textFieldParams = { type: "text", fullWidth: true, variant: "outlined", onChange: event => handleChange(event.target.name, event.target.value) };
    const [links, setLinks] = useState([]);
    const [userContext, setUserContext] = useContext(UserContext);
    const [, setModified] = useState(false);
    const autocompleteParams = { fullWidth: true, freeSolo: true, autoSelect: false, autoComplete: true, autoHighlight: false, blurOnSelect: true };

    const handleClose = () => props.setOpenModal(false);

    const handleChange = (index, name, value) => {
        let updatedLinks = [...links];
        updatedLinks[index][name] = value;
        setLinks(updatedLinks);
        setModified(true);
    };

    const handleAdd = () => {
        let url = process.env.REACT_APP_API_URL + "/links/generatetoken";
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.get(url, config)
            .then(res => {
                setLinks(links => [...links, { token: res.data.token, user: "", canEdit: false }]);
            })
            .catch(err => {
                // Do nothing
            });

        setModified(true);
    };

    const handleRemove = index => {
        let updatedLinks = [...links];
        updatedLinks.splice(index, 1);
        setLinks(updatedLinks);
        setModified(true);
    };

    const generateLink = token => {
        const splitUrl = window.location.href.split("/");
        const host = splitUrl[0] + "//" + splitUrl[2];
        return host + "/projects/" + props.projectId + "?token=" + token;
    };

    const handleSubmit = event => {
        event.preventDefault();
        setDisableSubmitButton(true);

        const url = process.env.REACT_APP_API_URL + "/links?project_id=" + props.projectId;
        const payload = links;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.post(url, payload, config)
            .then(res => {
                props.setOpenModal(false);
                props.setSnackbarMessage("Shared links updated");
                props.setOpenSnackbar(true);
                setUserContext(userContext => ({ ...userContext, refresh: !userContext.refresh }));
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableSubmitButton(false);
            });
    };

    useEffect(() => {
        const fetchLinks = () => {
            let url = process.env.REACT_APP_API_URL + "/links?project_id=" + props.projectId;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setLinks(res.data);
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchLinks();
    }, [userContext, setUserContext, props.projectId]);

    useEffect(() => {
        const fieldsNotEmpty = () => {
            return links.every(link => link.user);
        };

        setDisableSubmitButton(!fieldsNotEmpty());
    }, [links]);

    return (
        <Modal open={props.openModal} onClose={handleClose}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: 900,
                    width: "96%",
                    height: "96%",
                    bgcolor: "#e7ecef",
                    overflow: "auto"
                }}
            >
                <Grid container item justifyContent="flex-end" sx={{ padding: "2.5% 2.5% 2.5% 2.5%" }}>
                    <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                </Grid>
                <div style={{ padding: "0% 4% 4% 4%" }}>
                    <Grid container item justifyContent="center" sx={{ mb: "25px" }}>
                        <Typography variant="h5" align="center">Share Project</Typography>
                    </Grid>
                    <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px" }}>
                        <Typography color="black">Create a link to share this project</Typography>
                    </Grid>
                    <form onSubmit={handleSubmit}>
                        <Grid container direction="column" spacing={2.5}>
                            <Grid container item sx={{ mb: "30px" }}>
                                <Grid container item justifyContent="center">
                                    {links.map((link, index) => {
                                        return (
                                            <Paper
                                                key={index}
                                                sx={{
                                                    backgroundColor: "transparent",
                                                    padding: "30px 12px 30px 12px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    boxShadow: "none",
                                                    width: "100%",
                                                    border: "1px dotted #495464",
                                                    marginBottom: "30px"
                                                }}
                                            >
                                                <Grid container item direction="row" spacing={2.5} wrap="nowrap">
                                                    <Grid container item spacing={2.5}>
                                                        <Grid item xs={7} sx={{ minWidth: "250px" }}>
                                                            <TextField
                                                                {...textFieldParams}
                                                                name="token"
                                                                label="Link"
                                                                value={generateLink(link.token)}
                                                                onChange={event => handleChange(index, event.target.name, event.target.value)}
                                                                InputProps={{ readOnly: true, endAdornment: <CopyAdornment content={generateLink(link.token)} /> }}
                                                                InputLabelProps={{ shrink: true }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} sx={{ minWidth: "150px" }}>
                                                            <Autocomplete
                                                                {...autocompleteParams}
                                                                options={[]}
                                                                renderInput={params => (<TextField {...params} label="Person's name" value={link.user} inputProps={{ ...params.inputProps, autoCapitalize: "words" }} InputLabelProps={{ shrink: true, required: true }} />)}
                                                                onInputChange={(event, value) => handleChange(index, "user", value)}
                                                                inputValue={link.user}
                                                            />
                                                        </Grid>
                                                        <Grid item container xs={2} alignItems="center">
                                                            <FormControlLabel
                                                                checked={link.canEdit}
                                                                control={<Checkbox />}
                                                                onChange={(event, value) => handleChange(index, "canEdit", value)}
                                                                label="Edit"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item alignItems="center" sx={{ width: "fit-content" }}>
                                                        <IconButton tabIndex={-1} onClick={() => handleRemove(index)}><RemoveCircleOutlineOutlinedIcon /></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        )
                                    })}
                                    <Paper
                                        sx={{
                                            backgroundColor: "transparent",
                                            padding: "20px 12px 20px 12px",
                                            display: "flex",
                                            justifyContent: "center",
                                            boxShadow: "none",
                                            width: "100%",
                                            border: "1px dotted #495464",
                                            marginBottom: "30px"
                                        }}
                                    >
                                        <Grid container item onClick={handleAdd}>
                                            <Grid container item justifyContent="center">
                                                <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", textAlign: "center", fontSize: "14px" }}>Click to generate a new link</Typography>
                                            </Grid>
                                            <Grid container item justifyContent="center" sx={{ mt: "10px" }}>
                                                <Grid item>
                                                    <IconButton><AddCircleOutlineOutlinedIcon sx={{ width: 35, height: 35, color: "#495464" }} /></IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                            <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                            <Grid container item justifyContent="flex-end">
                                <Grid item sx={{ mr: 2.5 }}>
                                    <Button
                                        variant="outlined"
                                        sx={{ height: "45px", width: "120px" }}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        sx={{ height: "45px", width: "120px" }}
                                        type="submit"
                                        disabled={disableSubmitButton}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Paper>
        </Modal >
    );
};

export default LinksModal;