import ProjectTable from "../components/Projects/ProjectTable";
import { Grid, Typography } from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";
import { useContext, useState, useRef, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import TopBar from "../components/Layout/TopBar";
import TheSnackbar from "../components/Common/TheSnackbar";
import axios from "axios";

const Project = () => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [project, setProject] = useState({ name: "", description: "" });
    const topRef = useRef(null);
    const subtitleRef = useRef(null);
    const [snackbarMessage, setSnackbarMessage] = useState();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const location = useLocation();
    const [paginationModel, setPaginationModel] = useState({
        page: userContext.projectPage ? userContext.projectPage : 0,
        pageSize: window.localStorage.getItem("projectPageSize") !== null ? parseInt(window.localStorage.getItem("projectPageSize")) : 15
    });
    const [link, setLink] = useState({ canEdit: false, linkUser: "", token: "" });
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    useEffect(() => {
        if (location.state?.goToFirstPage) {
            setPaginationModel(paginationModel => ({ ...paginationModel, page: 0 }));
            setUserContext(userContext => ({ ...userContext, projectPage: 0 }));
        }

        if (location.state?.snackbarMessage) {
            setSnackbarMessage(location.state.snackbarMessage);
            setOpenSnackbar(true);
            window.history.replaceState({}, "");
        }
    }, [location, setUserContext]);

    useEffect(() => {
        if (token) {
            const getLinkInfo = () => {
                const url = process.env.REACT_APP_API_URL + "/links/" + token + "?token=" + token;

                axios.get(url)
                    .then(res => {
                        setLink(res.data);
                    })
                    .catch(err => {
                        // Do nothing
                    });
            };

            getLinkInfo();
        }
    }, [token]);

    return ((userContext.token || link.token) &&
        <Grid container direction="column" sx={{ maxWidth: "1000px" }} ref={topRef}>
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "10px" }}>
                <TopBar enableBack={userContext.token} title={project.name} subtitle={project.description} backRoute={"/main"} link={link} />
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px", paddingTop: "15px" }} ref={subtitleRef}>
                {userContext.token ?
                    <Typography color="black">View or edit the worklist below</Typography>
                    :
                    <Typography color="black">Luu Marine Worklist</Typography>
                }
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "25px" }} >
                <ProjectTable
                    project={project}
                    setProject={setProject}
                    subtitleRef={subtitleRef}
                    topRef={topRef}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    link={link}
                />
            </Grid>
        </Grid>
    );
};

export default Project;