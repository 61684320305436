import { Modal, Paper, Grid, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { UserContext } from "../../context/UserContext";
import ImageMarker from "react-image-marker";
import DotMarkerWithNumber from "../Common/DotMarkerWithNumber";
import { useSearchParams } from "react-router-dom";
import { axiosInstance } from "../../utils/utils";

const BlueprintModal = props => {
    const [markers, setMarkers] = useState([]);
    const [blueprint, setBlueprint] = useState();
    const [userContext, setUserContext] = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const blueprintRef = useRef(null);
    const [blueprintWidth, setBlueprintWidth] = useState();

    const handleClose = () => props.setOpenModal(false);

    const getImage = useCallback(async image => {
        let url = process.env.REACT_APP_API_URL + "/images/" + image;
        let config = {};

        if (token) {
            url += "?token=" + token;
            config = { responseType: "arraybuffer" };
        } else if (userContext.token) {
            config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext, responseType: "arraybuffer" };
        }

        try {
            const fetchRes = await axiosInstance.get(url, config);
            const blob = new Blob([fetchRes.data], { type: fetchRes.headers.getContentType() });
            return URL.createObjectURL(blob);
        }
        catch (err) {
            // Do nothing
        }
    }, [token, setUserContext, userContext]);

    useEffect(() => {
        const fetchBlueprintData = async () => {
            let url = process.env.REACT_APP_API_URL + "/projects/blueprintdata?id=" + props.project._id;
            let config = {};

            if (token) {
                url += "&token=" + token;
            } else if (userContext.token) {
                config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
            }

            try {
                const res = await axiosInstance.get(url, config);
                setMarkers(res.data.markers);
                setBlueprint(await getImage(res.data.blueprint));
            } catch (err) {
                // Do nothing
            }
        };

        fetchBlueprintData();
    }, [userContext, setUserContext, props.project._id, token, getImage]);

    return (blueprint &&
        <Modal open={props.openModal} onClose={() => props.setOpenModal(false)}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: 900,
                    width: "96%",
                    height: "96%",
                    bgcolor: "#e7ecef",
                    overflow: "auto"
                }}
            >
                <Grid container item justifyContent="flex-end" sx={{ padding: "2.5% 2.5% 2.5% 2.5%" }}>
                    <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                </Grid>
                <div style={{ padding: "0% 4% 4% 4%" }}>
                    <Typography variant="h5" align="center" sx={{ mb: "5px" }}>{props.project.name}</Typography>
                    <Typography variant="body1" align="center" sx={{ mb: "35px" }}>Workscope Overview</Typography>
                    <Grid container item alignItems="center" sx={{ minHeight: "100%" }} onLoad={() => setBlueprintWidth(blueprintRef.current.offsetWidth)} ref={blueprintRef}>
                        <ImageMarker
                            src={blueprint}
                            markers={markers}
                            markerComponent={markerComponentProps => <DotMarkerWithNumber markerComponentProps={markerComponentProps} imageWidth={blueprintWidth} />}
                        />
                    </Grid>
                </div>
            </Paper>
        </Modal >
    );
};

export default BlueprintModal;