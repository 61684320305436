import { Grid, Typography, Button, IconButton, ToggleButton, ToggleButtonGroup, Autocomplete, TextField } from "@mui/material";
import { useRef, useState, useEffect, useCallback } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CallMadeIcon from "@mui/icons-material/CallMade";
import UndoIcon from "@mui/icons-material/Undo";
import CreateIcon from "@mui/icons-material/Create";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";

const MarkImage = props => {
    const [tool, setTool] = useState("rectangle");
    const [imageWidth, setImageWidth] = useState();
    const [imageHeight, setImageHeight] = useState();
    const [scaleFactor, setScaleFactor] = useState();
    const [image, setImage] = useState();
    const canvasRef = useRef(null);
    const contextRef = useRef(null);
    const startX = useRef(null);
    const startY = useRef(null);
    const endX = useRef(null);
    const endY = useRef(null);
    const rotationX = useRef(null);
    const rotationY = useRef(null);
    const transformedStartX = useRef(null);
    const transformedStartY = useRef(null);
    const transformedEndX = useRef(null);
    const transformedEndY = useRef(null);
    const xShift = useRef(null);
    const yShift = useRef(null);
    const [canUndo, setCanUndo] = useState(false);
    const strokes = useRef([]);
    const currentStroke = useRef({});
    const isDrawing = useRef(false);
    const indexTransformed = useRef(-1);
    const transformed = useRef(false);
    const toggleButtonParams = color => {
        const border = color !== "rgba(0, 0, 0, 1)" ? "3px solid rgba(0, 0, 0, 0.3) !important" : "3px solid rgba(255, 255, 255, 0.3) !important";
        return {
            value: color,
            sx: {
                width: "25px",
                height: "25px",
                marginLeft: "5px",
                marginRight: "5px",
                borderRadius: "5px !important",
                backgroundColor: color,
                border: "3px solid transparent !important",
                ":hover": { backgroundColor: color, border: border },
                "&.Mui-selected": { backgroundColor: color + " !important", border: border }
            }
        }
    };
    const [color, setColor] = useState("rgba(237, 40, 40, 1)");
    const canvasPositionTop = useRef();
    const canvasPositionLeft = useRef();
    const [lineWidthOption, setLineWidthOption] = useState("1");
    const [lineWidth, setLineWidth] = useState();
    const [arcRadius, setArcRadius] = useState();
    const [canvasIsLoading, setCanvasIsLoading] = useState(true);
    const [pivotPointDistance, setPivotPointDistance] = useState(100);
    const [canDelete, setCanDelete] = useState(false);
    const autocompleteParams = { fullWidth: true, freeSolo: true, autoSelect: false, autoComplete: true, autoHighlight: false, blurOnSelect: true };
    const linesAreConnecting = useRef(false);
    const isDragging = useRef(false);

    // Handle mousedown and mouseup firing after touchend on touch devices
    useEffect(() => {
        if (!canvasIsLoading) {
            const handleTouchEnd = event => {
                event.preventDefault();
            };

            canvasRef.current.addEventListener("touchend", handleTouchEnd);
            return () => canvasRef.current.removeEventListener("touchend", handleTouchEnd);
        }
    }, [canvasIsLoading]);

    // Handle mousedown and mouseup firing after touchstart on touch devices
    useEffect(() => {
        if (!canvasIsLoading) {
            const handleTouchStart = event => {
                event.preventDefault();
            };

            canvasRef.current.addEventListener("touchstart", handleTouchStart);
            return () => canvasRef.current.removeEventListener("touchstart", handleTouchStart);
        }
    }, [canvasIsLoading]);

    // Calculate the scale factor to fit entire image onto screen
    useEffect(() => {
        const initialize = async () => {
            const background = new Image();
            background.src = props.image;
            await background.decode();

            setImageHeight(background.height);
            setImageWidth(background.width);

            if (background.width >= background.height) {
                // Scaling for landscape image
                setScaleFactor(Math.min(background.width, props.paperRef.current?.clientWidth * 0.92) / background.width);
            } else {
                // Scaling for portrait image
                setScaleFactor(Math.min(background.height, props.paperRef.current?.clientHeight * 0.45) / background.height);
            }

            setImage(background);
        }

        initialize();
    }, [props.image, props.paperRef, imageHeight, imageWidth]);

    useEffect(() => {
        const lineWidth = Math.min(imageWidth, imageHeight) * (parseInt(lineWidthOption) / 100);
        setLineWidth(lineWidth);
        setArcRadius((lineWidth * 4) / Math.log10(lineWidth));
        setPivotPointDistance(imageHeight * 0.15);
    }, [lineWidthOption, imageWidth, imageHeight]);

    const drawPoint = (startX, startY, color, arcRadius) => {
        contextRef.current.fillStyle = color;
        contextRef.current.beginPath();
        contextRef.current.arc(startX, startY, arcRadius, 0, 360, false);
        contextRef.current.fill();
    };

    const drawArrow = useCallback((startX, startY, endX, endY, color, lineWidth, arcRadius, selected) => {
        // Draw arrow main line
        contextRef.current.strokeStyle = color;
        contextRef.current.lineWidth = lineWidth;
        contextRef.current.beginPath();
        contextRef.current.moveTo(startX, startY);
        contextRef.current.lineTo(endX, endY);

        // Draw arrow top lines
        const dx = endX - startX;
        const dy = endY - startY;
        const headLength = Math.sqrt(dx * dx + dy * dy) * 0.4;
        const angle = Math.atan2(dy, dx);
        contextRef.current.moveTo(endX - headLength * Math.cos(angle - Math.PI / 6), endY - headLength * Math.sin(angle - Math.PI / 6));
        contextRef.current.lineTo(endX, endY);
        contextRef.current.lineTo(endX - headLength * Math.cos(angle + Math.PI / 6), endY - headLength * Math.sin(angle + Math.PI / 6));

        contextRef.current.stroke();

        if (selected) {
            //Draw points
            drawPoint(startX, startY, color, arcRadius); // Start point
            drawPoint(endX, endY, color, arcRadius); // End point
            drawPoint(endX - headLength * Math.cos(angle - Math.PI / 6), endY - headLength * Math.sin(angle - Math.PI / 6), color, arcRadius); // Left point
            drawPoint(endX - headLength * Math.cos(angle + Math.PI / 6), endY - headLength * Math.sin(angle + Math.PI / 6), color, arcRadius); // Right point
        }
    }, []);

    const drawLine = useCallback((startX, startY, endX, endY, color, lineWidth, arcRadius, selected) => {
        contextRef.current.strokeStyle = color;
        contextRef.current.lineWidth = lineWidth;
        contextRef.current.beginPath();
        contextRef.current.moveTo(startX, startY);
        contextRef.current.lineTo(endX, endY);
        contextRef.current.stroke();

        if (selected) {
            // Draw points
            drawPoint(startX, startY, color, arcRadius); // Start point
            drawPoint(endX, endY, color, arcRadius); // End point
        }
    }, []);

    const drawRectangle = useCallback((startX, startY, endX, endY, color, lineWidth, arcRadius, selected, rotationX, rotationY) => {
        contextRef.current.strokeStyle = color;
        contextRef.current.lineWidth = lineWidth;

        // Swap start and end points to draw from top left to bottom right
        if (startX > endX) {
            const tempX = startX;
            startX = endX;
            endX = tempX;
        }

        if (startY > endY) {
            const tempY = startY;
            startY = endY;
            endY = tempY;
        }

        // Rectangle width and height
        const rectWidth = endX - startX;
        const rectHeight = endY - startY;

        // Rotation point
        const rotationAnchorX = startX + rectWidth / 2;
        const rotationAnchorY = startY - pivotPointDistance;

        // Angle to move rectangle by
        let angle;

        if (rotationX === undefined
            || rotationX === null
            || rotationY === undefined
            || rotationY === null
        ) {
            angle = -Math.PI / 2;
        } else {
            angle = Math.atan2(rotationY - rotationAnchorY, rotationX - rotationAnchorX);
            /*
                Note that y is negative value due to direction on screen being opposite of Cartesian grid.
                But it doesn't matter because y positions are relative to the screen.
            */
        }

        // Begin rectangle path
        contextRef.current.beginPath();

        // Rectangle top midpoint
        const midTopRadius = pivotPointDistance;
        const midTopX = startX + rectWidth / 2;
        const midTopY = startY - midTopRadius;
        const midTopDeltaX = -Math.cos(angle) * midTopRadius;
        const midTopDeltaY = -Math.sin(angle) * midTopRadius;
        const transformedMidTopX = midTopX + midTopDeltaX;
        const transformedMidTopY = midTopY + midTopDeltaY;

        // Angle from pivot point to midpoint and pivot point to top left/right point
        const thetaTop = Math.atan2(rectWidth / 2, midTopRadius);

        // Distance from pivot point to top left/right point
        const topCornerRadius = Math.sqrt(midTopRadius * midTopRadius + (rectWidth * rectWidth) / 4);

        // Rectangle left top point
        const leftTopDeltaX = -Math.cos(angle + thetaTop) * topCornerRadius;
        const leftTopDeltaY = -Math.sin(angle + thetaTop) * topCornerRadius;
        const transformedLeftTopX = midTopX + leftTopDeltaX;
        const transformedLeftTopY = midTopY + leftTopDeltaY;

        // Rectangle right top point
        const rightTopDeltaX = -Math.cos(angle - thetaTop) * topCornerRadius;
        const rightTopDeltaY = -Math.sin(angle - thetaTop) * topCornerRadius;
        const transformedRightTopX = midTopX + rightTopDeltaX;
        const transformedRightTopY = midTopY + rightTopDeltaY;

        // Rectangle bottom midpoint
        const midBottomRadius = pivotPointDistance + rectHeight;
        const midBottomX = endX - rectWidth / 2;
        const midBottomY = endY - midBottomRadius;

        // Angle from pivot point to midpoint and pivot point to bottom left/right points
        let thetaBottom = Math.atan2(rectWidth / 2, midBottomRadius);

        // Distance from pivot point to bottom left/right points
        const bottomCornerRadius = Math.sqrt(midBottomRadius * midBottomRadius + (rectWidth * rectWidth) / 4);

        // Rectangle right bottom point
        const rightBottomDeltaX = -Math.cos(angle - thetaBottom) * bottomCornerRadius;
        const rightBottomDeltaY = -Math.sin(angle - thetaBottom) * bottomCornerRadius;
        const transformedRightBottomX = midBottomX + rightBottomDeltaX;
        const transformedRightBottomY = midBottomY + rightBottomDeltaY;

        // Rectangle left bottom point
        const leftBottomDeltaX = -Math.cos(angle + thetaBottom) * bottomCornerRadius;
        const leftBottomDeltaY = -Math.sin(angle + thetaBottom) * bottomCornerRadius;
        const transformedLeftBottomX = midBottomX + leftBottomDeltaX;
        const transformedLeftBottomY = midBottomY + leftBottomDeltaY;

        // Draw transformed rectangle
        contextRef.current.moveTo(transformedLeftTopX, transformedLeftTopY); // Left top corner
        contextRef.current.lineTo(transformedRightTopX, transformedRightTopY); // Top line
        contextRef.current.lineTo(transformedRightBottomX, transformedRightBottomY); // Right line
        contextRef.current.lineTo(transformedLeftBottomX, transformedLeftBottomY); // Bottom line
        contextRef.current.lineTo(transformedLeftTopX, transformedLeftTopY); // Left line
        contextRef.current.lineTo(transformedRightTopX, transformedRightTopY); // Additional top line to fill left top corner
        contextRef.current.stroke();

        if (selected) {
            // Draw points
            drawPoint(transformedLeftTopX, transformedLeftTopY, color, arcRadius); // Top left corner point
            drawPoint(transformedRightTopX, transformedRightTopY, color, arcRadius); // Top right corner point
            drawPoint(transformedRightBottomX, transformedRightBottomY, color, arcRadius); // Bottom right corner point
            drawPoint(transformedLeftBottomX, transformedLeftBottomY, color, arcRadius); // Bottom left corner point
            drawPoint(rotationAnchorX, rotationAnchorY, color, arcRadius); // Rotatation point
            drawLine(rotationAnchorX, rotationAnchorY, transformedMidTopX, transformedMidTopY, color, lineWidth, arcRadius, false); // Rotation line
        }
    }, [drawLine, pivotPointDistance]);

    const drawWithPen = useCallback((path, color, lineWidth, arcRadius, selected) => {
        contextRef.current.strokeStyle = color;
        contextRef.current.lineWidth = lineWidth;
        contextRef.current.lineCap = "round";
        contextRef.current.lineJoin = "round";
        contextRef.current.beginPath();

        path.forEach(path => {
            contextRef.current.lineTo(path.x, path.y);
        });

        contextRef.current.stroke();

        const pathlength = path.length;

        if (selected && pathlength > 0) {
            // Draw points
            drawPoint(path[0].x, path[0].y, color, arcRadius); // Start point
            drawPoint(path[pathlength - 1].x, path[pathlength - 1].y, color, arcRadius); // End point
        }
    }, []);

    const drawConnectedLines = useCallback((path, color, lineWidth, arcRadius, selected, endX, endY) => {
        contextRef.current.strokeStyle = color;
        contextRef.current.lineWidth = lineWidth;
        contextRef.current.beginPath();

        path.forEach(path => {
            contextRef.current.lineTo(path.x, path.y);
        });

        if (path.length > 1 && path[0].x === path[path.length - 1].x && path[0].y === path[path.length - 1].y) {
            // Fills in corner at first point
            contextRef.current.lineTo(path[1].x, path[1].y);
        }

        if (endX && endY) {
            contextRef.current.lineTo(endX, endY);
        }

        contextRef.current.stroke();

        const pathlength = path.length;

        if (selected && pathlength > 0) {
            // Draw points
            const arcRadius = (lineWidth * 4) / Math.log10(lineWidth);

            path.forEach(path => {
                drawPoint(path.x, path.y, color, arcRadius);
            });

            if (endX && endY) {
                drawPoint(endX, endY, color, arcRadius);
            }
        }
    }, []);

    const redrawAll = useCallback(() => {
        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        contextRef.current.drawImage(image, 0, 0, imageWidth, imageHeight);

        for (let stroke of strokes.current) {
            if (stroke.transformed || stroke.deleted) {
                continue;
            }

            const color = stroke.color;
            const lineWidth = stroke.lineWidth;
            const arcRadius = (lineWidth * 4) / Math.log10(lineWidth);
            const selected = stroke.selected;

            if (stroke.type === "rectangle") {
                drawRectangle(stroke.startX, stroke.startY, stroke.endX, stroke.endY, color, lineWidth, arcRadius, selected, stroke.rotationX, stroke.rotationY);
            } else if (stroke.type === "arrow") {
                drawArrow(stroke.startX, stroke.startY, stroke.endX, stroke.endY, color, lineWidth, arcRadius, selected);
            } else if (stroke.type === "pen") {
                drawWithPen(stroke.path, color, lineWidth, arcRadius, selected);
            } else if (stroke.type === "line") {
                drawLine(stroke.startX, stroke.startY, stroke.endX, stroke.endY, color, lineWidth, arcRadius, selected);
            } else if (stroke.type === "connectedLines") {
                drawConnectedLines(stroke.path, color, lineWidth, arcRadius, selected);
            }
        }
    }, [drawArrow, drawLine, drawRectangle, drawWithPen, drawConnectedLines, image, imageHeight, imageWidth]);

    const startTransforming = (startX, startY) => {
        let transformType = "";
        let transformedPointIndex = undefined;
        let stroke = null;

        try {
            // Draw currrent shapes non-visibly to check if selected
            const checkColor = "transparent";

            for (let i = strokes.current.length - 1; i >= 0; i--) {
                if (strokes.current[i].transformed) {
                    continue;
                }

                stroke = strokes.current[i];

                const checkLineWidth = Math.max(stroke.lineWidth, 25);
                const arcRadius = (lineWidth * 4) / Math.log10(lineWidth);
                contextRef.current.strokeStyle = checkColor;
                contextRef.current.lineWidth = checkLineWidth;

                if (stroke.type === "rectangle") {
                    // Rectangle width and height
                    const rectWidth = stroke.endX - stroke.startX;
                    const rectHeight = stroke.endY - stroke.startY;

                    // Rotation point
                    const rotationAnchorX = stroke.startX + rectWidth / 2;
                    const rotationAnchorY = stroke.startY - pivotPointDistance;

                    // Angle to move rectangle by
                    let angle;

                    if (stroke.rotationX === undefined || stroke.rotationY === undefined) {
                        angle = -Math.PI / 2;
                    } else {
                        angle = Math.atan2(stroke.rotationY - rotationAnchorY, stroke.rotationX - rotationAnchorX);
                    }

                    // Begin rectangle path
                    contextRef.current.beginPath();

                    // Rectangle top midpoint
                    const midTopRadius = pivotPointDistance;
                    const midTopX = stroke.startX + rectWidth / 2;
                    const midTopY = stroke.startY - midTopRadius;
                    const midTopDeltaX = -Math.cos(angle) * midTopRadius;
                    const midTopDeltaY = -Math.sin(angle) * midTopRadius;
                    const transformedMidTopX = midTopX + midTopDeltaX;
                    const transformedMidTopY = midTopY + midTopDeltaY;

                    // Angle from pivot point to midpoint and pivot point to top left/right point
                    let thetaTop = Math.atan2(rectWidth / 2, midTopRadius);

                    // Distance from pivot point to top left/right point
                    const topCornerRadius = Math.sqrt(midTopRadius * midTopRadius + (rectWidth * rectWidth) / 4);

                    // Rectangle left top point
                    const leftTopX = midTopX;
                    const leftTopY = midTopY;
                    const leftTopDeltaX = -Math.cos(angle + thetaTop) * topCornerRadius;
                    const leftTopDeltaY = -Math.sin(angle + thetaTop) * topCornerRadius;
                    const transformedLeftTopX = leftTopX + leftTopDeltaX;
                    const transformedLeftTopY = leftTopY + leftTopDeltaY;

                    // Rectangle left top point adjusting for line stroke width
                    const midTopRadiusAdjusted = midTopRadius - lineWidth / 2;
                    const topCornerRadiusAdjusted = Math.sqrt(midTopRadiusAdjusted * midTopRadiusAdjusted + (rectWidth * rectWidth) / 4);
                    const thetaTopAdjusted = Math.atan2(rectWidth / 2, midTopRadiusAdjusted);
                    const leftTopXAdjusted = midTopX;
                    const leftTopYAdjusted = midTopY;
                    const leftTopDeltaXAdjusted = -Math.cos(angle + thetaTopAdjusted) * topCornerRadiusAdjusted;
                    const leftTopDeltaYAdjusted = -Math.sin(angle + thetaTopAdjusted) * topCornerRadiusAdjusted;
                    const transformedLeftTopXAdjusted = leftTopXAdjusted + leftTopDeltaXAdjusted;
                    const transformedLeftTopYAdjusted = leftTopYAdjusted + leftTopDeltaYAdjusted;

                    // Rectangle right top point
                    const rightTopX = midTopX;
                    const rightTopY = midTopY;
                    const rightTopDeltaX = -Math.cos(angle - thetaTop) * topCornerRadius;
                    const rightTopDeltaY = -Math.sin(angle - thetaTop) * topCornerRadius;
                    const transformedRightTopX = rightTopX + rightTopDeltaX;
                    const transformedRightTopY = rightTopY + rightTopDeltaY;

                    // Rectangle bottom midpoint
                    const midBottomRadius = pivotPointDistance + rectHeight;
                    const midBottomX = stroke.endX - rectWidth / 2;
                    const midBottomY = stroke.endY - midBottomRadius;

                    // Angle from pivot point to midpoint and pivot point to bottom left/right points
                    const thetaBottom = Math.atan2(rectWidth / 2, midBottomRadius);

                    // Distance from pivot point to bottom left/right points
                    const bottomCornerRadius = Math.sqrt(midBottomRadius * midBottomRadius + (rectWidth * rectWidth) / 4);

                    // Rectangle right bottom point
                    const rightBottomX = midBottomX;
                    const rightBottomY = midBottomY;
                    const rightBottomDeltaX = -Math.cos(angle - thetaBottom) * bottomCornerRadius;
                    const rightBottomDeltaY = -Math.sin(angle - thetaBottom) * bottomCornerRadius;
                    const transformedRightBottomX = rightBottomX + rightBottomDeltaX;
                    const transformedRightBottomY = rightBottomY + rightBottomDeltaY;

                    // Rectangle left bottom point
                    const leftBottomX = midBottomX;
                    const leftBottomY = midBottomY;
                    const leftBottomDeltaX = -Math.cos(angle + thetaBottom) * bottomCornerRadius;
                    const leftBottomDeltaY = -Math.sin(angle + thetaBottom) * bottomCornerRadius;
                    const transformedLeftBottomX = leftBottomX + leftBottomDeltaX;
                    const transformedLeftBottomY = leftBottomY + leftBottomDeltaY;

                    if (!stroke.selected) {
                        // Handle select rectangle
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        contextRef.current.moveTo(transformedLeftTopX, transformedLeftTopY);
                        contextRef.current.lineTo(transformedRightTopX, transformedRightTopY);
                        contextRef.current.lineTo(transformedRightBottomX, transformedRightBottomY);
                        contextRef.current.lineTo(transformedLeftBottomX, transformedLeftBottomY);
                        contextRef.current.lineTo(transformedLeftTopXAdjusted, transformedLeftTopYAdjusted);
                        contextRef.current.stroke();

                        if (contextRef.current.isPointInStroke(startX, startY)) {
                            strokes.current.forEach(stroke => stroke.selected = false);
                            stroke.selected = true;
                            transformType = "select";
                            break;
                        }
                    } else {
                        // Handle click on selected rectangle top left corner
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawPoint(transformedLeftTopX, transformedLeftTopY, checkColor, arcRadius);

                        if (contextRef.current.isPointInPath(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "resizeTopLeftPoint";
                            break;
                        }

                        // Handle click on selected rectangle bottom left corner
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawPoint(transformedLeftBottomX, transformedLeftBottomY, checkColor, arcRadius);

                        if (contextRef.current.isPointInPath(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "resizeBottomLeftPoint";
                            break;
                        }

                        // Handle click on selected rectangle bottom right corner
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawPoint(transformedRightBottomX, transformedRightBottomY, checkColor, arcRadius);

                        if (contextRef.current.isPointInPath(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "resizeBottomRightPoint";
                            break;
                        }

                        // Handle click on selected rectangle top right corner
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawPoint(transformedRightTopX, transformedRightTopY, checkColor, arcRadius);

                        if (contextRef.current.isPointInPath(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "resizeTopRightPoint";
                            break;
                        }

                        // Handle click on selected rectangle rotation point
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawPoint(rotationAnchorX, rotationAnchorY, checkColor, arcRadius);

                        if (contextRef.current.isPointInPath(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "rotate";
                            break;
                        }

                        // Handle click on selected rectangle rotation line
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawLine(rotationAnchorX, rotationAnchorY, transformedMidTopX, transformedMidTopY, color, lineWidth, arcRadius, false); // Rotation line

                        if (contextRef.current.isPointInPath(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "move";
                            break;
                        }

                        // Handle click on selected rectangle
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        contextRef.current.moveTo(transformedLeftTopX, transformedLeftTopY);
                        contextRef.current.lineTo(transformedRightTopX, transformedRightTopY);
                        contextRef.current.lineTo(transformedRightBottomX, transformedRightBottomY);
                        contextRef.current.lineTo(transformedLeftBottomX, transformedLeftBottomY);
                        contextRef.current.lineTo(transformedLeftTopXAdjusted, transformedLeftTopYAdjusted);
                        contextRef.current.stroke();

                        if (contextRef.current.isPointInStroke(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "move";
                            break;
                        }
                    }
                } else if (stroke.type === "arrow") {
                    const dx = stroke.endX - stroke.startX;
                    const dy = stroke.endY - stroke.startY;
                    const headLength = (Math.sqrt(dx * dx + dy * dy) * 0.4);
                    const angle = Math.atan2(dy, dx);

                    if (!stroke.selected) {
                        // Handle select arrow
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawArrow(stroke.startX, stroke.startY, stroke.endX, stroke.endY, checkColor, checkLineWidth, arcRadius, false);

                        if (contextRef.current.isPointInStroke(startX, startY)) {
                            strokes.current.forEach(stroke => stroke.selected = false);
                            stroke.selected = true;
                            transformType = "select";
                            break;
                        }
                    } else {
                        // Initialize start and end points for transformation
                        transformedStartX.current = stroke.startX;
                        transformedStartY.current = stroke.startY;
                        transformedEndX.current = stroke.endX;
                        transformedEndY.current = stroke.endY;

                        // Handle click on start point
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawPoint(stroke.startX, stroke.startY, checkColor, arcRadius);

                        if (contextRef.current.isPointInPath(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "startPoint";
                            break;
                        }

                        // Handle click on end point
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawPoint(stroke.endX, stroke.endY, checkColor, arcRadius);

                        if (contextRef.current.isPointInPath(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "endPoint";
                            break;
                        }

                        // Handle click on left point
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawPoint(stroke.endX - headLength * Math.cos(angle + Math.PI / 6), stroke.endY - headLength * Math.sin(angle + Math.PI / 6), checkColor, arcRadius);

                        if (contextRef.current.isPointInPath(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "leftPoint";
                            break;
                        }

                        // Handle click on right point
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawPoint(stroke.endX - headLength * Math.cos(angle - Math.PI / 6), stroke.endY - headLength * Math.sin(angle - Math.PI / 6), checkColor, arcRadius);

                        if (contextRef.current.isPointInPath(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "rightPoint";
                            break;
                        }

                        // Handle click on arrow
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawArrow(stroke.startX, stroke.startY, stroke.endX, stroke.endY, checkColor, checkLineWidth, arcRadius, false);

                        if (contextRef.current.isPointInStroke(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "move";
                            break;
                        }
                    }
                } else if (stroke.type === "pen") {
                    if (!stroke.selected) {
                        // Handle select pen path
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawWithPen(stroke.path, checkColor, checkLineWidth, arcRadius, false);

                        if (contextRef.current.isPointInStroke(startX, startY)) {
                            strokes.current.forEach(stroke => stroke.selected = false);
                            stroke.selected = true;
                            transformType = "select";
                            break;
                        }
                    } else {
                        // Handle click on start point
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawPoint(stroke.startX, stroke.startY, checkColor, arcRadius);

                        if (contextRef.current.isPointInPath(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "startPoint";
                            break;
                        }

                        // Handle click on end point
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawPoint(stroke.endX, stroke.endY, checkColor, arcRadius);

                        if (contextRef.current.isPointInPath(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "endPoint";
                            break;
                        }

                        // Handle click on selected pen path
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawWithPen(stroke.path, checkColor, checkLineWidth, arcRadius, false);

                        if (contextRef.current.isPointInStroke(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "move";
                            break;
                        }
                    }
                } else if (stroke.type === "line") {
                    if (!stroke.selected) {
                        // Handle select line
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawLine(stroke.startX, stroke.startY, stroke.endX, stroke.endY, checkColor, checkLineWidth, arcRadius, false);

                        if (contextRef.current.isPointInStroke(startX, startY)) {
                            strokes.current.forEach(stroke => stroke.selected = false);
                            stroke.selected = true;
                            transformType = "select";
                            break;
                        }
                    } else {
                        // Handle click on start point
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawPoint(stroke.startX, stroke.startY, checkColor, arcRadius);

                        if (contextRef.current.isPointInPath(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "startPoint";
                            break;
                        }

                        // Handle click on end point
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawPoint(stroke.endX, stroke.endY, checkColor, arcRadius);

                        if (contextRef.current.isPointInPath(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "endPoint";
                            break;
                        }

                        // Handle click on selected line
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawLine(stroke.startX, stroke.startY, stroke.endX, stroke.endY, checkColor, checkLineWidth, arcRadius, false);

                        if (contextRef.current.isPointInStroke(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "move";
                            break;
                        }
                    }
                } else if (stroke.type === "connectedLines") {
                    if (!stroke.selected) {
                        // Handle select path
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawConnectedLines(stroke.path, checkColor, checkLineWidth, arcRadius, false);

                        if (contextRef.current.isPointInStroke(startX, startY)) {
                            strokes.current.forEach(stroke => stroke.selected = false);
                            stroke.selected = true;
                            transformType = "select";
                            break;
                        }
                    } else {
                        // Handle click on a point
                        for (let j = 0; j < stroke.path.length; j++) {
                            contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                            drawPoint(stroke.path[j].x, stroke.path[j].y, checkColor, arcRadius);

                            if (contextRef.current.isPointInPath(startX, startY)) {
                                indexTransformed.current = i;
                                transformType = "point";
                                transformedPointIndex = j;
                                break;
                            }
                        }

                        if (indexTransformed.current >= 0) {
                            break;
                        }

                        // Handle click on line in selected path
                        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                        drawConnectedLines(stroke.path, checkColor, checkLineWidth, arcRadius, false);

                        if (contextRef.current.isPointInStroke(startX, startY)) {
                            indexTransformed.current = i;
                            transformType = "move";
                            break;
                        }
                    }
                }
            }
        } catch (err) {
            // Do nothing
        }

        // Clear out previous selections
        if (transformType !== "select" && indexTransformed.current < 0) {
            strokes.current.forEach(stroke => stroke.selected = false);
            setCanDelete(false);
        } else if (transformType === "select") {
            setCanDelete(true);
            const lineWidthStr = "" + Math.trunc((stroke.lineWidth * 100) / Math.min(imageWidth, imageHeight));
            setLineWidthOption(lineWidthStr);
            setColor(stroke.color);
        }

        // Reset stroke line width and color
        contextRef.current.strokeStyle = color;
        contextRef.current.lineWidth = lineWidth;

        // Redraw to clear transparent strokes used for checking selection above
        redrawAll();

        // Create new stroke for current transformation
        if (indexTransformed.current >= 0) {
            let updatedStroke = { ...stroke };

            if (stroke.path) {
                updatedStroke.path = structuredClone(stroke.path);
            }

            if (transformedPointIndex !== undefined) {
                updatedStroke.transformedPointIndex = transformedPointIndex;
            }

            currentStroke.current = {
                ...updatedStroke,
                indexTransformed: indexTransformed.current,
                transformType: transformType,
                selected: true
            };

            isDrawing.current = true;
            stroke.transformed = true;
        }
    };

    const transform = (startX, startY, endX, endY) => {
        xShift.current = endX - startX;
        yShift.current = endY - startY;

        redrawAll();

        const stroke = currentStroke.current;
        transformed.current = true;
        const arcRadius = Math.max((lineWidth * 4) / Math.log10(lineWidth), props.paperRef.current?.clientWidth * 0.1);

        if (stroke.type === "rectangle") {
            if (stroke.transformType === "move") {
                if (stroke.rotationX && stroke.rotationY) {
                    rotationX.current = stroke.rotationX + xShift.current;
                    rotationY.current = stroke.rotationY + yShift.current;
                }

                transformedStartX.current = stroke.startX + xShift.current;
                transformedStartY.current = stroke.startY + yShift.current;
                transformedEndX.current = stroke.endX + xShift.current;
                transformedEndY.current = stroke.endY + yShift.current;
            } else if (stroke.transformType === "resizeTopLeftPoint") {
                transformedStartX.current = endX;
                transformedStartY.current = endY;
                transformedEndX.current = stroke.endX;
                transformedEndY.current = stroke.endY;

                if (stroke.rotationX && stroke.rotationY) {
                    // Rotate original left top point to determine offset
                    const rectWidth = stroke.endX - stroke.startX;
                    const rotationAnchorX = stroke.startX + rectWidth / 2;
                    const rotationAnchorY = stroke.startY - pivotPointDistance;
                    const angle = Math.atan2(stroke.rotationY - rotationAnchorY, stroke.rotationX - rotationAnchorX);
                    const midTopRadius = pivotPointDistance;
                    const midTopX = stroke.startX + rectWidth / 2;
                    const midTopY = stroke.startY - midTopRadius;
                    const thetaTop = Math.atan2(rectWidth / 2, midTopRadius);
                    const topCornerRadius = Math.sqrt(midTopRadius * midTopRadius + (rectWidth * rectWidth) / 4);
                    const leftTopDeltaX = -Math.cos(angle + thetaTop) * topCornerRadius;
                    const leftTopDeltaY = -Math.sin(angle + thetaTop) * topCornerRadius;
                    const rotatedLeftTopX = midTopX + leftTopDeltaX;
                    const rotatedLeftTopY = midTopY + leftTopDeltaY;

                    // Determine offset angle
                    const xDiff = endX - rotatedLeftTopX;
                    const yDiff = endY - rotatedLeftTopY;
                    const oldToNewDistance = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
                    const angleToNewPoint = Math.atan2(yDiff, xDiff) + angle;

                    // Move left top point
                    const xShift = Math.cos(angleToNewPoint) * oldToNewDistance;
                    const yShift = Math.sin(angleToNewPoint) * oldToNewDistance;
                    transformedStartX.current = stroke.startX + xShift;
                    transformedStartY.current = stroke.startY + yShift;

                    // Move rotation point
                    const transformedRectWidth = transformedEndX.current - transformedStartX.current;
                    const transformedRotationAnchorX = transformedStartX.current + transformedRectWidth / 2;
                    const transformedRotationAnchorY = transformedStartY.current - pivotPointDistance;
                    const rotationAnchorXShift = transformedRotationAnchorX - rotationAnchorX;
                    const rotationAnchorYShift = transformedRotationAnchorY - rotationAnchorY;

                    rotationX.current = stroke.rotationX + rotationAnchorXShift;
                    rotationY.current = stroke.rotationY + rotationAnchorYShift;
                }
            } else if (stroke.transformType === "resizeBottomLeftPoint") {
                transformedStartX.current = stroke.startX + xShift.current;
                transformedStartY.current = stroke.startY;
                transformedEndY.current = stroke.endY + yShift.current;
                transformedEndX.current = stroke.endX;

                if (stroke.rotationX && stroke.rotationY) {
                    // Rotate original left bottom point to determine offset
                    const rectWidth = stroke.endX - stroke.startX;
                    const rectHeight = stroke.endY - stroke.startY;
                    const rotationAnchorX = stroke.startX + rectWidth / 2;
                    const rotationAnchorY = stroke.startY - pivotPointDistance;
                    const angle = Math.atan2(stroke.rotationY - rotationAnchorY, stroke.rotationX - rotationAnchorX);
                    const midBottomRadius = pivotPointDistance + rectHeight;
                    const midBottomX = stroke.endX - rectWidth / 2;
                    const midBottomY = stroke.endY - midBottomRadius;
                    const thetaBottom = Math.atan2(rectWidth / 2, midBottomRadius);
                    const bottomCornerRadius = Math.sqrt(midBottomRadius * midBottomRadius + (rectWidth * rectWidth) / 4);
                    const leftBottomDeltaX = -Math.cos(angle + thetaBottom) * bottomCornerRadius;
                    const leftBottomDeltaY = -Math.sin(angle + thetaBottom) * bottomCornerRadius;
                    const rotatedLeftBottomX = midBottomX + leftBottomDeltaX;
                    const rotatedLeftBottomY = midBottomY + leftBottomDeltaY;

                    // Determine offset angle
                    const xDiff = endX - rotatedLeftBottomX;
                    const yDiff = endY - rotatedLeftBottomY;
                    const oldToNewDistance = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
                    const angleToNewPoint = Math.atan2(yDiff, xDiff) + angle;

                    // Move left bottom point
                    const xShift = Math.cos(angleToNewPoint) * oldToNewDistance;
                    const yShift = Math.sin(angleToNewPoint) * oldToNewDistance;
                    transformedStartX.current = stroke.startX + xShift;
                    transformedEndY.current = stroke.endY + yShift;

                    // Move rotation point
                    const transformedRectWidth = transformedEndX.current - transformedStartX.current;
                    const transformedRotationAnchorX = transformedStartX.current + transformedRectWidth / 2;
                    const transformedRotationAnchorY = transformedStartY.current - pivotPointDistance;
                    const rotationAnchorXShift = transformedRotationAnchorX - rotationAnchorX;
                    const rotationAnchorYShift = transformedRotationAnchorY - rotationAnchorY;

                    rotationX.current = stroke.rotationX + rotationAnchorXShift;
                    rotationY.current = stroke.rotationY + rotationAnchorYShift;
                }
            } else if (stroke.transformType === "resizeBottomRightPoint") {
                transformedStartX.current = stroke.startX;
                transformedStartY.current = stroke.startY;
                transformedEndX.current = stroke.endX + xShift.current;
                transformedEndY.current = stroke.endY + yShift.current;

                if (stroke.rotationX && stroke.rotationY) {
                    // Rotate original right bottom point to determine offset
                    const rectWidth = stroke.endX - stroke.startX;
                    const rectHeight = stroke.endY - stroke.startY;
                    const rotationAnchorX = stroke.startX + rectWidth / 2;
                    const rotationAnchorY = stroke.startY - pivotPointDistance;
                    const angle = Math.atan2(stroke.rotationY - rotationAnchorY, stroke.rotationX - rotationAnchorX);
                    const midBottomRadius = pivotPointDistance + rectHeight;
                    const midBottomX = stroke.endX - rectWidth / 2;
                    const midBottomY = stroke.endY - midBottomRadius;
                    const thetaBottom = Math.atan2(rectWidth / 2, midBottomRadius);
                    const bottomCornerRadius = Math.sqrt(midBottomRadius * midBottomRadius + (rectWidth * rectWidth) / 4);
                    const rightBottomDeltaX = -Math.cos(angle - thetaBottom) * bottomCornerRadius;
                    const rightBottomDeltaY = -Math.sin(angle - thetaBottom) * bottomCornerRadius;
                    const rotatedRightBottomX = midBottomX + rightBottomDeltaX;
                    const rotatedRightBottomY = midBottomY + rightBottomDeltaY;

                    // Determine offset angle
                    const xDiff = endX - rotatedRightBottomX;
                    const yDiff = endY - rotatedRightBottomY;
                    const oldToNewDistance = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
                    const angleToNewPoint = Math.atan2(yDiff, xDiff) + angle;

                    // Move right bottom point
                    const xShift = Math.cos(angleToNewPoint) * oldToNewDistance;
                    const yShift = Math.sin(angleToNewPoint) * oldToNewDistance;
                    transformedEndX.current = stroke.endX + xShift;
                    transformedEndY.current = stroke.endY + yShift;

                    // Move rotation point
                    const transformedRectWidth = transformedEndX.current - transformedStartX.current;
                    const transformedRotationAnchorX = transformedStartX.current + transformedRectWidth / 2;
                    const transformedRotationAnchorY = transformedStartY.current - pivotPointDistance;
                    const rotationAnchorXShift = transformedRotationAnchorX - rotationAnchorX;
                    const rotationAnchorYShift = transformedRotationAnchorY - rotationAnchorY;

                    rotationX.current = stroke.rotationX + rotationAnchorXShift;
                    rotationY.current = stroke.rotationY + rotationAnchorYShift;
                }
            } else if (stroke.transformType === "resizeTopRightPoint") {
                transformedStartX.current = stroke.startX;
                transformedStartY.current = stroke.startY + yShift.current;
                transformedEndX.current = stroke.endX + xShift.current;
                transformedEndY.current = stroke.endY;

                if (stroke.rotationX && stroke.rotationY) {
                    // Rotate original right top point to determine offset
                    const rectWidth = stroke.endX - stroke.startX;
                    const rotationAnchorX = stroke.startX + rectWidth / 2;
                    const rotationAnchorY = stroke.startY - pivotPointDistance;
                    const angle = Math.atan2(stroke.rotationY - rotationAnchorY, stroke.rotationX - rotationAnchorX);
                    const midTopRadius = pivotPointDistance;
                    const midTopX = stroke.startX + rectWidth / 2;
                    const midTopY = stroke.startY - midTopRadius;
                    const thetaTop = Math.atan2(rectWidth / 2, midTopRadius);
                    const topCornerRadius = Math.sqrt(midTopRadius * midTopRadius + (rectWidth * rectWidth) / 4);
                    const rightTopDeltaX = -Math.cos(angle - thetaTop) * topCornerRadius;
                    const rightTopDeltaY = -Math.sin(angle - thetaTop) * topCornerRadius;
                    const rotatedRightTopX = midTopX + rightTopDeltaX;
                    const rotatedRightTopY = midTopY + rightTopDeltaY;

                    // Determine offset angle
                    const xDiff = endX - rotatedRightTopX;
                    const yDiff = endY - rotatedRightTopY;
                    const oldToNewDistance = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
                    const angleToNewPoint = Math.atan2(yDiff, xDiff) + angle;

                    // Move right top point
                    const xShift = Math.cos(angleToNewPoint) * oldToNewDistance;
                    const yShift = Math.sin(angleToNewPoint) * oldToNewDistance;
                    transformedEndX.current = stroke.endX + xShift;
                    transformedStartY.current = stroke.startY + yShift;

                    // Move rotation point
                    const transformedRectWidth = transformedEndX.current - transformedStartX.current;
                    const transformedRotationAnchorX = transformedStartX.current + transformedRectWidth / 2;
                    const transformedRotationAnchorY = transformedStartY.current - pivotPointDistance;
                    const rotationAnchorXShift = transformedRotationAnchorX - rotationAnchorX;
                    const rotationAnchorYShift = transformedRotationAnchorY - rotationAnchorY;

                    rotationX.current = stroke.rotationX + rotationAnchorXShift;
                    rotationY.current = stroke.rotationY + rotationAnchorYShift;
                }
            } else if (stroke.transformType === "rotate") {
                transformedStartX.current = stroke.startX;
                transformedStartY.current = stroke.startY;
                transformedEndX.current = stroke.endX;
                transformedEndY.current = stroke.endY;
                rotationX.current = endX;
                rotationY.current = endY;
            }

            const color = stroke.color;
            const lineWidth = stroke.lineWidth;
            const arcRadius = (lineWidth * 4) / Math.log10(lineWidth);

            drawRectangle(transformedStartX.current, transformedStartY.current, transformedEndX.current, transformedEndY.current, color, lineWidth, arcRadius, true, rotationX.current, rotationY.current);
        } else if (stroke.type === "arrow") {
            if (stroke.transformType === "move") {
                transformedStartX.current = stroke.startX + xShift.current;
                transformedStartY.current = stroke.startY + yShift.current;
                transformedEndX.current = stroke.endX + xShift.current;
                transformedEndY.current = stroke.endY + yShift.current;
            } else if (stroke.transformType === "startPoint") {
                transformedStartX.current = endX;
                transformedStartY.current = endY;
            } else if (stroke.transformType === "endPoint") {
                transformedEndX.current = endX;
                transformedEndY.current = endY;
            } else if (stroke.transformType === "leftPoint") {
                const dx = transformedEndX.current - transformedStartX.current;
                const dy = transformedEndY.current - transformedStartY.current;
                const headLength = (Math.sqrt(dx * dx + dy * dy) * 0.4);
                const angle = Math.atan2(dy, dx);
                transformedEndX.current = endX + headLength * Math.cos(angle + Math.PI / 6);
                transformedEndY.current = endY + headLength * Math.sin(angle + Math.PI / 6);
            } else if (stroke.transformType === "rightPoint") {
                const dx = transformedEndX.current - transformedStartX.current;
                const dy = transformedEndY.current - transformedStartY.current;
                const headLength = (Math.sqrt(dx * dx + dy * dy) * 0.4);
                const angle = Math.atan2(dy, dx);
                transformedEndX.current = endX + headLength * Math.cos(angle - Math.PI / 6);
                transformedEndY.current = endY + headLength * Math.sin(angle - Math.PI / 6);
            }

            drawArrow(transformedStartX.current, transformedStartY.current, transformedEndX.current, transformedEndY.current, stroke.color, lineWidth, arcRadius, true);
        } else if (stroke.type === "pen") {
            const path = structuredClone(stroke.path);

            path.forEach(path => {
                path.x += xShift.current;
                path.y += yShift.current;
            });

            drawWithPen(path, stroke.color, lineWidth, arcRadius, true);
        } else if (stroke.type === "line") {
            if (stroke.transformType === "move") {
                transformedStartX.current = stroke.startX + xShift.current;
                transformedStartY.current = stroke.startY + yShift.current;
                transformedEndX.current = stroke.endX + xShift.current;
                transformedEndY.current = stroke.endY + yShift.current;
            } else if (stroke.transformType === "startPoint") {
                transformedStartX.current = endX;
                transformedStartY.current = endY;
                transformedEndX.current = stroke.endX;
                transformedEndY.current = stroke.endY;
            } else if (stroke.transformType === "endPoint") {
                transformedStartX.current = stroke.startX;
                transformedStartY.current = stroke.startY;
                transformedEndX.current = endX;
                transformedEndY.current = endY;
            }

            drawLine(transformedStartX.current, transformedStartY.current, transformedEndX.current, transformedEndY.current, stroke.color, lineWidth, arcRadius, true);
        } else if (stroke.type === "connectedLines") {
            const path = structuredClone(stroke.path);

            if (stroke.transformType === "move") {
                path.forEach(path => {
                    path.x += xShift.current;
                    path.y += yShift.current;
                });
            } else if (stroke.transformType === "point") {
                const pathLength = stroke.path.length;

                path[stroke.transformedPointIndex].x += xShift.current;
                path[stroke.transformedPointIndex].y += yShift.current;

                if (stroke.transformedPointIndex === 0) {
                    path[pathLength - 1].x += xShift.current;
                    path[pathLength - 1].y += yShift.current;
                } else if (stroke.transformedPointIndex === pathLength - 1) {
                    path[0].x += xShift.current;
                    path[0].y += yShift.current;
                }
            }

            drawConnectedLines(path, stroke.color, lineWidth, arcRadius, true);
        } else {
            transformed.current = false;
        }
    };

    const startDrawing = ({ nativeEvent }) => {
        nativeEvent.stopPropagation();

        if ((isDrawing.current || nativeEvent.touches?.length > 1) && !linesAreConnecting.current) {
            return;
        }

        if (tool !== "select") {
            isDrawing.current = true;
            strokes.current.forEach(stroke => stroke.selected = false);
            setCanDelete(false);
        }

        const x = nativeEvent.clientX ? nativeEvent.pageX : nativeEvent.touches[0].pageX;
        const y = nativeEvent.clientY ? nativeEvent.pageY : nativeEvent.touches[0].pageY;

        if (tool !== "pen") {
            startX.current = (x - canvasRef.current.getBoundingClientRect().left - window.scrollX) / scaleFactor;
            startY.current = (y - canvasRef.current.getBoundingClientRect().top - window.scrollY) / scaleFactor;
        }

        if (tool === "rectangle") {
            currentStroke.current = { type: "rectangle", startX: startX.current, startY: startY.current, color: color, lineWidth: lineWidth };
        } else if (tool === "arrow") {
            currentStroke.current = { type: "arrow", startX: startX.current, startY: startY.current, color: color, lineWidth: lineWidth };
        } else if (tool === "pen") {
            currentStroke.current = { type: "pen", path: [], color: color, lineWidth: lineWidth };
        } else if (tool === "line") {
            currentStroke.current = { type: "line", startX: startX.current, startY: startY.current, color: color, lineWidth: lineWidth };
        } else if (tool === "select") {
            startTransforming(startX.current, startY.current);
        } else if (tool === "connectedLines") {
            const arcRadius = (lineWidth * 4) / Math.log10(lineWidth);
            isDragging.current = true;

            // Add first point to path
            if (!linesAreConnecting.current) {
                linesAreConnecting.current = true;
                currentStroke.current = { type: "connectedLines", path: [{ x: startX.current, y: startY.current }], color: color, lineWidth: lineWidth };
                drawConnectedLines(currentStroke.current.path, currentStroke.current.color, lineWidth, arcRadius, true);
            }

            endX.current = startX.current;
            endY.current = startY.current;
        } else {
            isDrawing.current = false;
        }
    };

    const draw = ({ nativeEvent }) => {
        nativeEvent.stopPropagation();

        if (!isDrawing.current) {
            return;
        }

        if (nativeEvent.touches?.length > 1) {
            resetRefs();
            return;
        }

        const x = nativeEvent.clientX ? nativeEvent.pageX : nativeEvent.touches[0].pageX;
        const y = nativeEvent.clientY ? nativeEvent.pageY : nativeEvent.touches[0].pageY;

        redrawAll();

        if (tool === "rectangle") {
            currentStroke.current.endX = endX.current = (x - canvasRef.current.getBoundingClientRect().left - window.scrollX) / scaleFactor;
            currentStroke.current.endY = endY.current = (y - canvasRef.current.getBoundingClientRect().top - window.scrollY) / scaleFactor;
            drawRectangle(startX.current, startY.current, endX.current, endY.current, currentStroke.current.color, lineWidth, arcRadius);
        } else if (tool === "arrow") {
            currentStroke.current.endX = endX.current = (x - canvasRef.current.getBoundingClientRect().left - window.scrollX) / scaleFactor;
            currentStroke.current.endY = endY.current = (y - canvasRef.current.getBoundingClientRect().top - window.scrollY) / scaleFactor;
            drawArrow(startX.current, startY.current, endX.current, endY.current, currentStroke.current.color, lineWidth, arcRadius);
        } else if (tool === "pen") {
            currentStroke.current.path.push(
                {
                    x: (x - canvasRef.current.getBoundingClientRect().left - window.scrollX) / scaleFactor,
                    y: (y - canvasRef.current.getBoundingClientRect().top - window.scrollY) / scaleFactor
                }
            );
            drawWithPen(currentStroke.current.path, currentStroke.current.color, lineWidth, arcRadius);
        } else if (tool === "line") {
            currentStroke.current.endX = endX.current = (x - canvasRef.current.getBoundingClientRect().left - window.scrollX) / scaleFactor;
            currentStroke.current.endY = endY.current = (y - canvasRef.current.getBoundingClientRect().top - window.scrollY) / scaleFactor;
            drawLine(startX.current, startY.current, endX.current, endY.current, currentStroke.current.color, lineWidth, arcRadius);
        } else if (tool === "select") {
            endX.current = (x - canvasRef.current.getBoundingClientRect().left - window.scrollX) / scaleFactor;
            endY.current = (y - canvasRef.current.getBoundingClientRect().top - window.scrollY) / scaleFactor;
            transform(startX.current, startY.current, endX.current, endY.current);
        } else if (tool === "connectedLines") {
            const arcRadius = (lineWidth * 4) / Math.log10(lineWidth);

            if (linesAreConnecting.current) {
                endX.current = (x - canvasRef.current.getBoundingClientRect().left - window.scrollX) / scaleFactor;
                endY.current = (y - canvasRef.current.getBoundingClientRect().top - window.scrollY) / scaleFactor;

                if (isDragging.current) {
                    drawConnectedLines(currentStroke.current.path, currentStroke.current.color, lineWidth, arcRadius, true, endX.current, endY.current);
                } else {
                    drawConnectedLines(currentStroke.current.path, currentStroke.current.color, lineWidth, arcRadius, true);
                }
            }
        }
    };

    const stopDrawing = ({ nativeEvent }) => {
        nativeEvent.stopPropagation();

        if (!isDrawing.current) {
            return;
        } else if (tool === "select") {
            if (!transformed.current) {
                if (indexTransformed.current >= 0) {
                    strokes.current[indexTransformed.current].transformed = false;
                }

                resetRefs();
                return;
            } else {
                const type = currentStroke.current.type;

                if (type === "pen") {
                    currentStroke.current.path.forEach(path => {
                        path.x += xShift.current;
                        path.y += yShift.current;
                    });
                } else if (type === "connectedLines") {
                    const transformType = currentStroke.current.transformType;

                    if (transformType === "move") {
                        currentStroke.current.path.forEach(path => {
                            path.x += xShift.current;
                            path.y += yShift.current;
                        });
                    } else if (transformType === "point") {
                        const transformedPointIndex = currentStroke.current.transformedPointIndex;
                        const pathLength = currentStroke.current.path.length;

                        currentStroke.current.path[transformedPointIndex].x += xShift.current;
                        currentStroke.current.path[transformedPointIndex].y += yShift.current;

                        if (transformedPointIndex === 0) {
                            currentStroke.current.path[pathLength - 1].x += xShift.current;
                            currentStroke.current.path[pathLength - 1].y += yShift.current;
                        } else if (transformedPointIndex === pathLength - 1) {
                            currentStroke.current.path[0].x += xShift.current;
                            currentStroke.current.path[0].y += yShift.current;
                        }
                    }
                } else {
                    currentStroke.current.startX = transformedStartX.current;
                    currentStroke.current.startY = transformedStartY.current;
                    currentStroke.current.endX = transformedEndX.current;
                    currentStroke.current.endY = transformedEndY.current;

                    if (type === "rectangle") {
                        // Swap start and end points to draw from top left to bottom right
                        if (currentStroke.current.startX > currentStroke.current.endX) {
                            const tempX = currentStroke.current.startX;
                            currentStroke.current.startX = currentStroke.current.endX;
                            currentStroke.current.endX = tempX;
                        }

                        if (currentStroke.current.startY > currentStroke.current.endY) {
                            const tempY = currentStroke.current.startY;
                            currentStroke.current.startY = currentStroke.current.endY;
                            currentStroke.current.endY = tempY;
                        }
                    }
                }

                if (rotationX.current) {
                    currentStroke.current.rotationX = rotationX.current;
                }

                if (rotationY.current) {
                    currentStroke.current.rotationY = rotationY.current;
                }

                strokes.current[indexTransformed.current].selected = false;
            }
        } else if (tool === "rectangle") {
            // Swap start and end points to draw from top left to bottom right
            if (currentStroke.current.startX > currentStroke.current.endX) {
                const tempX = currentStroke.current.startX;
                currentStroke.current.startX = currentStroke.current.endX;
                currentStroke.current.endX = tempX;
            }

            if (currentStroke.current.startY > currentStroke.current.endY) {
                const tempY = currentStroke.current.startY;
                currentStroke.current.startY = currentStroke.current.endY;
                currentStroke.current.endY = tempY;
            }
        } else if (linesAreConnecting.current) {
            isDragging.current = false;
            const arcRadius = (lineWidth * 4) / Math.log10(lineWidth);
            const checkColor = "transparent";

            // Complete drawing the path if first point is clicked
            if (currentStroke.current.path.length > 1) {
                drawPoint(currentStroke.current.path[0].x, currentStroke.current.path[0].y, checkColor, arcRadius);

                if (contextRef.current.isPointInPath(endX.current, endY.current)) {
                    currentStroke.current.path.push({ x: currentStroke.current.path[0].x, y: currentStroke.current.path[0].y });
                    drawConnectedLines(currentStroke.current.path, currentStroke.current.color, lineWidth, arcRadius, true);
                    linesAreConnecting.current = false;
                    isDrawing.current = true;

                    strokes.current.push({ ...currentStroke.current });
                    setCanUndo(true);
                    resetRefs();
                    redrawAll();

                    return;
                }
            }

            // Add the clicked point to the path
            currentStroke.current.path.push({ x: endX.current, y: endY.current });
            drawConnectedLines(currentStroke.current.path, currentStroke.current.color, lineWidth, arcRadius, true);

            return;
        }

        strokes.current.push({ ...currentStroke.current });
        setCanUndo(true);
        resetRefs();
    };

    const resetRefs = () => {
        indexTransformed.current = -1;
        transformed.current = false;
        currentStroke.current = {};
        transformedStartX.current = null;
        transformedStartY.current = null;
        transformedEndX.current = null;
        transformedEndY.current = null;
        xShift.current = null;
        yShift.current = null;
        startX.current = null;
        startY.current = null;
        endX.current = null;
        endY.current = null;
        rotationX.current = null;
        rotationY.current = null;
        isDrawing.current = false;
        linesAreConnecting.current = false;
        isDragging.current = false;
        isDrawing.current = false;
    };

    const initialize = canvas => {
        if (canvas && !canvasRef.current) {
            canvasRef.current = canvas;
            canvasRef.current.width = imageWidth;
            canvasRef.current.height = imageHeight;
            canvasRef.current.style.background = `url(${props.image})`;
            canvasRef.current.style.backgroundSize = "cover";
            const context = canvas.getContext("2d");
            context.strokeStyle = color;
            context.fillStyle = color;
            context.lineWidth = lineWidth;
            contextRef.current = context;
            canvasPositionTop.current = canvasRef.current.getBoundingClientRect().top;
            canvasPositionLeft.current = canvasRef.current.getBoundingClientRect().left;
            setCanvasIsLoading(false);
            redrawAll();
        }
    };

    const handleUndo = () => {
        resetRefs();

        const removedStroke = strokes.current.pop();

        if (removedStroke.indexTransformed !== null && removedStroke.indexTransformed !== undefined) {
            strokes.current[removedStroke.indexTransformed].transformed = false;
        } else if (removedStroke.indexDeleted !== null && removedStroke.indexDeleted !== undefined) {
            strokes.current[removedStroke.indexDeleted].deleted = false;
        }

        if (removedStroke.selected) {
            setCanDelete(false);
        }

        redrawAll();
        setCanUndo(strokes.current.length > 0);
    };

    const handleDelete = () => {
        const updatedStrokes = [...strokes.current];

        for (let i = strokes.current.length - 1; i >= 0; i--) {
            if (strokes.current[i].selected) {
                strokes.current[i].deleted = true;
                strokes.current[i].selected = false;
                updatedStrokes.push({ type: "deletion", indexDeleted: i });
                strokes.current = updatedStrokes;
                setCanDelete(false);
                redrawAll();
                break;
            }
        }

    };

    const handleNext = () => {
        props.setMarkedImage(canvasRef.current.toDataURL("image/jpeg"));
    };

    // Handle changing the color of the selected shape
    useEffect(() => {
        const changeShapeColor = () => {
            const selectedIndex = strokes.current.findIndex(stroke => stroke.selected === true);

            if (selectedIndex !== -1) {
                const updatedStroke = structuredClone(strokes.current[selectedIndex]);
                updatedStroke.color = color;
                updatedStroke.indexTransformed = selectedIndex;
                strokes.current[selectedIndex].selected = false;
                strokes.current[selectedIndex].transformed = true;
                strokes.current.push(updatedStroke);
                redrawAll();
            }
        };

        if (color) {
            changeShapeColor();
        }
    }, [color, redrawAll]);

    // Handle changing the line width of the selected shape
    useEffect(() => {
        const changeShapeLineWidth = () => {
            const selectedIndex = strokes.current.findIndex(stroke => stroke.selected === true);

            if (selectedIndex !== -1) {
                const updatedStroke = structuredClone(strokes.current[selectedIndex]);
                updatedStroke.lineWidth = lineWidth;
                updatedStroke.indexTransformed = selectedIndex;
                strokes.current[selectedIndex].selected = false;
                strokes.current[selectedIndex].transformed = true;
                strokes.current.push(updatedStroke);
                redrawAll();
            }
        };

        if (lineWidth) {
            changeShapeLineWidth();
        }
    }, [lineWidth, redrawAll]);

    useEffect(() => {
        if (strokes.current.length > 0) {
            strokes.current.forEach(stroke => stroke.selected = false);
            redrawAll();
        }
    }, [tool, redrawAll]);

    return (scaleFactor &&
        <Grid container direction="column">
            <Grid container item justifyContent="center" sx={{ mb: "25px" }}>
                <Typography variant="h5" align="center">Add Markings</Typography>
            </Grid>
            <Grid container item justifyContent="center" sx={{ userSelect: "none" }}>
                <canvas
                    ref={canvas => initialize(canvas)}
                    onMouseDown={startDrawing}
                    onMouseMove={draw}
                    onMouseUp={stopDrawing}
                    onMouseLeave={() => tool !== "connectedLines" ? stopDrawing : null}
                    onTouchStart={startDrawing}
                    onTouchMove={draw}
                    onTouchEnd={stopDrawing}
                    style={{
                        width: imageWidth * scaleFactor,
                        height: imageHeight * scaleFactor,
                        marginBottom: "30px",
                        touchAction: "pinch-zoom"
                    }}
                />
            </Grid>
            <Grid container item direction="row" spacing={0.25} justifyContent="center">
                <Grid item>
                    <IconButton
                        onClick={() => setTool("arrow")}
                        sx={{
                            color: "#274c77",
                            backgroundColor: tool === "arrow" ? "rgba(0, 0, 0, 0.1)" : "transparent",
                            borderRadius: "5px",
                            ":hover": {
                                backgroundColor: tool === "arrow" ? "rgba(0, 0, 0, 0.1)" : null,
                                borderRadius: "5px"
                            }
                        }}
                    >
                        <CallMadeIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={() => setTool("rectangle")}
                        sx={{
                            color: "#274c77",
                            backgroundColor: tool === "rectangle" ? "rgba(0, 0, 0, 0.1)" : "transparent",
                            borderRadius: "5px",
                            ":hover": {
                                backgroundColor: tool === "rectangle" ? "rgba(0, 0, 0, 0.1)" : null,
                                borderRadius: "5px"
                            }
                        }}
                    >
                        <CheckBoxOutlineBlankIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={() => setTool("pen")}
                        sx={{
                            color: "#274c77",
                            backgroundColor: tool === "pen" ? "rgba(0, 0, 0, 0.1)" : "transparent",
                            borderRadius: "5px",
                            ":hover": {
                                backgroundColor: tool === "pen" ? "rgba(0, 0, 0, 0.1)" : null,
                                borderRadius: "5px"
                            }
                        }}
                    >
                        <CreateIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={() => setTool("connectedLines")}
                        sx={{
                            color: "#274c77",
                            backgroundColor: tool === "connectedLines" ? "rgba(0, 0, 0, 0.1)" : "transparent",
                            borderRadius: "5px",
                            ":hover": {
                                backgroundColor: tool === "connectedLines" ? "rgba(0, 0, 0, 0.1)" : null,
                                borderRadius: "5px"
                            }
                        }}
                    >
                        <PolylineOutlinedIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={() => setTool("select")}
                        sx={{
                            color: "#274c77",
                            backgroundColor: tool === "select" ? "rgba(0, 0, 0, 0.1)" : "transparent",
                            borderRadius: "5px",
                            ":hover": {
                                backgroundColor: tool === "select" ? "rgba(0, 0, 0, 0.1)" : null,
                                borderRadius: "5px"
                            }
                        }}
                    >
                        <PanToolAltIcon sx={{ transform: "rotate(-45deg) scale(1.3)" }} />
                    </IconButton>
                </Grid>
                <Grid item sx={{ ml: 3 }}>
                    <IconButton onClick={handleDelete} sx={{ color: "#274c77" }} disabled={!canDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton onClick={handleUndo} sx={{ color: "#274c77" }} disabled={!canUndo}>
                        <UndoIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container item sx={{ marginTop: 2.5 }} direction="row" justifyContent="center" alignItems="center">
                <Grid item>
                    <ToggleButtonGroup
                        value={color}
                        exclusive
                        onChange={event => setColor(event.target.value)}
                    >
                        <ToggleButton {...toggleButtonParams("rgba(237, 40, 40, 1)")} />
                        <ToggleButton {...toggleButtonParams("rgba(235, 122, 35, 1)")} />
                        <ToggleButton {...toggleButtonParams("rgba(235, 225, 35, 1)")} />
                        <ToggleButton {...toggleButtonParams("rgba(38, 148, 21, 1)")} />
                        <ToggleButton {...toggleButtonParams("rgba(21, 55, 148, 1)")} />
                        <ToggleButton {...toggleButtonParams("rgba(74, 21, 148, 1)")} />
                        <ToggleButton {...toggleButtonParams("rgba(0, 0, 0, 1)")} />
                        <ToggleButton {...toggleButtonParams("rgba(255, 255, 255, 1)")} />
                    </ToggleButtonGroup>
                </Grid>
                <Grid item sx={{ ml: 2 }}>
                    <Autocomplete
                        {...autocompleteParams}
                        options={["1", "2", "3", "4", "5"]}
                        renderInput={params => (
                            <TextField
                                {...params}
                                value={lineWidthOption}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ ...params.inputProps, readOnly: true, sx: { textAlign: "center", padding: "1px !important" } }}
                                sx={{ width: "45px" }}
                            />
                        )}
                        onChange={(event, value) => setLineWidthOption(value)}
                        inputValue={lineWidthOption}
                        disableClearable
                        filterOptions={options => options}
                        blurOnSelect={true}
                        size="small"
                    />
                </Grid>
            </Grid>
            <Grid container item justifyContent="center">
                <Button
                    onClick={handleNext}
                    sx={{
                        width: "100%",
                        marginTop: "35px",
                        height: "45px",
                        marginBottom: "35px"
                    }}
                >
                    Finish
                </Button>
            </Grid>
        </Grid >
    );
};

export default MarkImage;