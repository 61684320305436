import { InputAdornment, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CopyAdornment = props => {
    return (
        <InputAdornment position="end" sx={{ "& .MuiSvgIcon-root": { color: "rgba(0, 0, 0, 0.54)" } }}>
            <IconButton onClick={() => navigator.clipboard.writeText(props.content)} tabIndex={-1}>
                <ContentCopyIcon />
            </IconButton>
        </InputAdornment>
    );
};

export default CopyAdornment;