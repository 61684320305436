import { Grid, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ErrorBox = props => {
    const align = props.align ? props.align : "center";

    return (
        <>
            {props.showErrorMessage ?
                <Grid item>
                    <Typography color="error" sx={{ display: "flex", justifyContent: align, alignItems: "center" }}>
                        <ErrorOutlineIcon sx={{ mr: 1 }} /> {props.errorMessage}
                    </Typography>
                </Grid>
                :
                null
            }
        </>
    );
};

export default ErrorBox;